<template>
  <v-container>
    <v-layout row wrap justify-space-between class="mb-2">
      <v-flex xs6 sm4 md4 lg4 class="mb-1 mt-1">
        <v-btn
          :color="$store.state.secondaryColor"
          outlined
          small
          @click="goBack"
        >
          <v-icon small left>mdi-arrow-left</v-icon>
          <span class="caption">{{ $t("back") }}</span>
        </v-btn>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs6 sm4 md4 lg4 class="mb-1 text-right" v-if="details">
        <v-btn
          small
          outlined
          class="mr-3 mt-1"
          @click="editEmployee($route.params.id)"
          :color="$store.state.primaryColor"
        >
          {{ $t("edit") }}
          <v-icon small>edit</v-icon>
        </v-btn>
        <v-btn
          small
          outlined
          class="mr-3 mt-1"
          @click="delDialog = true"
          :color="$store.state.secondaryColor"
        >
          {{ $t("delete") }}
          <v-icon small>delete</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>

    <div v-show="pleaseWait" class="mt-3">
      <PleaseWait></PleaseWait>
    </div>
    <v-divider></v-divider>

    <v-dialog v-model="delDialog" persistent max-width="290">
      <v-card>
        <div class="text-center">
          <v-btn class="my-5" outlined large fab color="red darken-4">
            <v-icon>warning</v-icon>
          </v-btn>
        </div>

        <v-card-text class="text-center">
          <p>{{ $t("are you sure") }}?</p>
          <p>
            {{ $t("do you really want to delete") }}
            <span class="font-weight-medium">
              {{
                details.names.last +
                " " +
                details.names.first +
                " " +
                details.names.middle
              }} </span
            >?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :color="$store.state.secondaryColor"
            text
            small
            @click="delDialog = false"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :color="$store.state.primaryColor"
            text
            :loading="loading"
            small
            @click="deleteEmployee"
          >
            {{ $t("confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      top
      center
      color="success lighten-5 success--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("employee deleted successfully") }}.</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="success darken-3"
          @click="snackbar = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-layout row wrap justify-space-between class="caption" v-if="details">
      <v-flex xs12 sm12 md6 lg6 class="pa-1">
        <div class="elevation-2 mt-2">
          <v-card class="mx-auto mb-4" max-width="200">
            <v-img :src="details.avatar" contain></v-img>
          </v-card>
        </div>
        <v-list dense class="elevation-1">
          <v-list-item class="d-flex flex-wrap">
            <v-col cols="12" sm="6" md="6" lg="6">
              <span class="font-weight-black mr-2">{{ $t("name") }} : </span>
              <span class="body-2">{{ fullName }} </span>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="6">
              <span class="font-weight-black mr-2">{{ $t("gender") }}: </span>
              <span class="body-2">{{ details.gender }} </span>
            </v-col>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item class="d-flex flex-wrap">
            <v-col cols="12" sm="6" md="6" lg="6">
              <span class="font-weight-black mr-2">{{ $t("email") }} : </span>
              <span class="body-2">{{ details.email }} </span>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6">
              <span class="font-weight-black mr-2"
                >{{ $t("telephone") }}:
              </span>
              <span class="body-2">{{ details.telephone }} </span>
            </v-col>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item class="d-flex flex-wrap">
            <v-col cols="12" sm="6" md="6" lg="6">
              <span class="font-weight-bold mr-2"
                >{{ $t("employed at") }}:</span
              >
              <span class="body-2">
                {{ getBusinessName(details.businessId) }}
              </span>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="6">
              <span class="font-weight-black mr-2"
                >{{ $t("id-passport") }} :
              </span>
              <span class="body-2">{{ details.employeeIdPassport }} </span>
            </v-col>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item class="d-flex flex-wrap">
            <v-col cols="12" sm="6" md="6" lg="6">
              <span class="font-weight-black mr-2">{{ $t("role") }} : </span>
              <span class="body-2">{{ setRole(details.role) }} </span>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="6">
              <span class="font-weight-black mr-2">{{ $t("title") }} : </span>
              <span class="body-2">{{ details.title }} </span>
            </v-col>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item class="d-flex flex-wrap">
            <v-col cols="12" sm="6" md="6" lg="6">
              <span class="font-weight-black mr-2">{{ $t("status") }}: </span>
              <span class="body-2">{{ details.employeeStatus }} </span>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="6">
              <span class="font-weight-black mr-2">{{ $t("shift") }}: </span>
              <span class="body-2">{{ getShiftName(details.shiftId) }} </span>
            </v-col>
          </v-list-item>
        </v-list>
      </v-flex>
      <v-flex xs12 sm12 md6 lg6 class="pa-1">
        <v-list dense class="elevation-1">
          <v-list-item class="d-flex flex-wrap">
            <v-col cols="12" sm="6" md="6" lg="6">
              <span class="font-weight-black mr-2"
                >{{ $t("registered on") }}:
              </span>
              <span class="caption font-weight-light"
                >{{ details.dateCreated }}
              </span>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="6">
              <span class="font-weight-black mr-2">{{ $t("country") }} : </span>
              <span class="body-2">{{ details.address.country }} </span>
            </v-col>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item class="d-flex flex-wrap">
            <v-col cols="12" sm="6" md="6" lg="6">
              <span class="font-weight-black mr-2">{{ $t("county") }}: </span>
              <span class="body-2">{{ details.address.county }} </span>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="6">
              <span class="font-weight-black mr-2">{{ $t("city") }}: </span>
              <span class="body-2">{{ details.address.city }} </span>
            </v-col>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item class="d-flex flex-wrap">
            <v-col cols="12" sm="6" md="6" lg="6">
              <span class="font-weight-black mr-2">{{ $t("street") }}: </span>
              <span class="body-2">{{ details.address.street }} </span>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="6">
              <span class="font-weight-black mr-2">{{ $t("estate") }}: </span>
              <span class="body-2">{{ details.address.estate }} </span>
            </v-col>
          </v-list-item>

          <v-divider></v-divider>
          <v-list-item class="d-flex flex-wrap">
            <v-col cols="12" sm="6" md="6" lg="6">
              <span class="font-weight-black mr-2">{{ $t("building") }}: </span>
              <span class="body-2">{{ details.address.building }}</span>
            </v-col>

            <v-col cols="12" sm="6" md="6" lg="6">
              <span class="font-weight-black mr-2"
                >{{ $t("house-suite-number") }}:
              </span>
              <span class="body-2">{{ details.address.no }}</span>
            </v-col>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item class="d-flex flex-wrap">
            <v-col cols="12" sm="6" md="6" lg="6">
              <span class="font-weight-black mr-2">{{ $t("PO BOX") }}: </span>
              <span class="body-2">{{ details.address.pobox }} </span>
            </v-col>
          </v-list-item>
        </v-list>
      </v-flex>
      <v-flex xs12 sm12 md6 lg6 class="pa-1">
        <v-list dense class="elevation-1">
          <v-list-item class="d-flex flex-wrap">
            <v-col
              cols="12"
              sm="12"
              md="12"
              lg="12"
              v-if="details.employmentDocuments"
            >
              <p
                class="body-2 text-center"
                v-if="details.employmentDocuments.length > 0"
              >
                {{ $t("uploaded documents") }}
              </p>
              <v-btn
                block
                text
                class="grey lighten-3 my-1"
                v-for="documents in details.employmentDocuments"
                :key="documents.documentType"
              >
                <v-layout row wrap>
                  <v-flex xs8 sm8 md8 lg8>
                    {{ documents.documentType }}
                  </v-flex>
                  <v-flex xs2 sm2 md2 lg2>
                    <v-btn
                      x-small
                      right
                      color="info"
                      @click="
                        documentView(documents.documentType, documents.base64)
                      "
                      >{{ $t("view") }}</v-btn
                    >
                  </v-flex>
                </v-layout>
              </v-btn>
            </v-col>
            <p class="body-2 text-center" v-else>
              {{ $t("no uploaded documents") }}
            </p>
          </v-list-item>
        </v-list>
      </v-flex>
    </v-layout>

    <v-dialog
      v-model="documentsDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <EmploymentDocuments
        :documentType="this.documentType"
        :base64="this.base64"
        @documentsDialog="documentsDialog = false"
      ></EmploymentDocuments
    ></v-dialog>
  </v-container>
</template>

<script>
import db from "@/plugins/fb";
import EmploymentDocuments from "@/components/templates/EmploymentDocuments";
import PleaseWait from "@/components/templates/PleaseWait";
export default {
  components: {
    EmploymentDocuments,
    PleaseWait,
  },
  data: () => ({
    documentsDialog: false,
    base64: "",
    documentType: "",
    details: "",
    delDialog: false,
    snackbar: false,
    pleaseWait: true,
    shifts: [],
    timeout: 2000,
    loading: false,
  }),

  computed: {
    currentBusiness() {
      return this.$store.getters.getCurrentBusiness;
    },

    ownersBusinessIds() {
      return this.$store.getters.getBusinessId;
    },
    fullName() {
      return (
        this.details.names.last +
        " " +
        this.details.names.first +
        " " +
        this.details.names.middle
      );
    },
  },

  created() {
    this.getEmployeeDetails();
  },

  methods: {
    getEmployeeDetails() {
      db.collection("employees")
        .doc(this.$route.params.id)
        .get()
        .then((doc) => {
          this.details = doc.data();
          this.pleaseWait = false;
        })
        .catch((error) => {
          console.log("Error getting  document:", error);
        });
    },

    getBusinessName(id) {
      let busId = this.$store.state.businesses.find((item) => item.id == id);
      let busName = busId.businessName;
      return busName;
    },

    editEmployee(empID) {
      this.$router.push({ name: "EditEmployee", params: { id: empID } });
    },
    getShiftName(id) {
      let shift = this.$store.state.shifts.find((item) => item.id == id);

      let shiftName = shift.shiftName;
      return shiftName;
    },

    documentView(documentType, base64) {
      this.documentType = documentType;
      this.base64 = base64;
      this.documentsDialog = true;
    },
    deleteEmployee() {
      this.loading = true;
      db.collection("employees")
        .doc(this.$route.params.id)
        .delete()
        .then(() => {
          this.loading = false;
          this.delDialog = false;
          this.snackbar = true;

          setTimeout(() => this.goBack(), 2000);
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
        });
    },

    setRole(role) {
      if (role == "Manager") {
        return "Manager";
      } else {
        return "Surbodinate staff";
      }
    },
    goBack() {
      window.history.back();
    },
  },
};
</script>

<style></style>
